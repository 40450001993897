import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function ArticleCard({
  title,
  summary,
  linkText,
  linkAddr,
  image,
}: {
  title: string
  summary?: string
  linkText: string
  linkAddr: string
  image: 'basics/MagnifyingGlass' | 'basics/Pedigree' | 'basics/Pills'
}): JSX.Element {
  function changePage(): void {
    navigate(linkAddr)
  }

  function getImage(): JSX.Element {
    switch (image) {
      case 'basics/MagnifyingGlass':
        return (
          <StaticImage
            src="../../images/basics/MagnifyingGlass.png"
            alt="Magnifying Glass"
            placeholder="blurred"
            aspectRatio={1 / 1}
            onClick={() => changePage()}
          />
        )

      case 'basics/Pedigree':
        return (
          <StaticImage
            src="../../images/basics/Pedigree.png"
            alt="Pedigree Chart"
            placeholder="blurred"
            aspectRatio={1 / 1}
            onClick={() => changePage()}
          />
        )

      case 'basics/Pills':
        return (
          <StaticImage
            src="../../images/basics/Pills.png"
            alt="Pills"
            placeholder="blurred"
            aspectRatio={1 / 1}
            onClick={() => changePage()}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div className="articleCard">
      <h2>{title}</h2>
      <div className="imgContainer">{getImage()}</div>
      {summary && <h5>{summary}</h5>}
      <a
        role="link"
        tabIndex={0}
        onClick={() => changePage()}
        onKeyDown={() => changePage()}
        className="externalLink"
      >
        {linkText}
      </a>
      <div className="hrContainer">
        <hr />
      </div>
    </div>
  )
}
