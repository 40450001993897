import React from 'react'

import ArticleCard from '../components/cards/ArticleCard'
import Layout from '../components/layout'

export default function Basics(): JSX.Element {
  return (
    <Layout>
      <div id="articleContainer">
        <ArticleCard
          title="Genetic Inheritance"
          linkText="Read More!"
          linkAddr="/inheritance"
          image="basics/MagnifyingGlass"
        />

        <ArticleCard
          title="Genetic Diseases"
          linkText="Read More!"
          linkAddr="/diseases"
          image="basics/Pedigree"
        />
      </div>
    </Layout>
  )
}
